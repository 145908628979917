// src/services/api.js
export const API_BASE = 'https://dev02.mattiabiancaterra.com/api';

export async function fetchData(ticker, start, end, interval) {
  try {
    let url = `${API_BASE}/data/${ticker}`;
    const params = [];

    if (start) params.push(`start=${encodeURIComponent(start)}`);
    if (end) params.push(`end=${encodeURIComponent(end)}`);
    if (interval) params.push(`interval=${encodeURIComponent(interval)}`);

    if (params.length > 0) {
      url += `?${params.join('&')}`;
    }

    const response = await fetch(url);
    if (!response.ok) {
      // If an error, parse the body (could be "Yahoo Finance error: ...")
      let errorMsg = 'Unknown error';
      try {
        const errJson = await response.json();
        if (errJson.detail) {
          errorMsg = errJson.detail;
        }
      } catch (err) {
        // fallback to text
        const errText = await response.text();
        if (errText) errorMsg = errText;
      }
      throw new Error(errorMsg);
    }

    return await response.json();
  } catch (error) {
    console.error(`Error fetching data for ${ticker}:`, error);
    throw error;
  }
}
