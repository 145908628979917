//Dashboard.js

import React, { useEffect, useState } from 'react';
import { fetchData } from '../services/api';
import Chart from '../components/Chart';
import RSIChart from '../components/RSIChart';
import './Dashboard.css';

// 1) Import react-responsive & breakpoints (optional)
import { useMediaQuery } from 'react-responsive';
import { breakpoints } from '../utils/breakpoints';

function Dashboard() {
  // Default tickers
  const [tickers, setTickers] = useState(['ES=F', 'MSFT', 'CL=F', 'AAPL']);
  // Data & loading states
  const [dataMap, setDataMap] = useState({});
  const [loadingMap, setLoadingMap] = useState({});
  const [errors, setErrors] = useState([]);

  // Ticker input
  const [newTicker, setNewTicker] = useState('');

  // 2) Determine if we're in mobile or desktop
  const isMobile = useMediaQuery({ maxWidth: breakpoints.mobile - 1 });

  // =========== NEW: Date & Interval States ============
  // Default to year-to-date daily
  const currentYear = new Date().getFullYear();
  const defaultStart = `${currentYear}-01-01`;    // e.g. "2023-01-01"
  const defaultToday = new Date().toISOString().split('T')[0];

  const [startDate, setStartDate] = useState(defaultStart);
  const [endDate, setEndDate] = useState(defaultToday);
  const [selectedInterval, setSelectedInterval] = useState('1d'); // daily default

  // Popup error
  const [popupError, setPopupError] = useState(null);

  // ============ FETCH DATA ============

  const fetchTickerData = async (ticker) => {
    setLoadingMap((prev) => ({ ...prev, [ticker]: true }));
    try {
      const data = await fetchData(ticker, startDate, endDate, selectedInterval);
      if (data) {
        setDataMap((prevDataMap) => ({ ...prevDataMap, [ticker]: data }));
        setErrors((prevErrors) => prevErrors.filter((err) => err !== ticker));
      } else {
        throw new Error('Invalid data format');
      }
    } catch (error) {
      console.error(`Error fetching data for ${ticker}:`, error);
      setErrors((prevErrors) => [...prevErrors, ticker]);
      setPopupError(error.message);
    } finally {
      setLoadingMap((prev) => ({ ...prev, [ticker]: false }));
    }
  };

  // ============ HANDLERS ============

  // Add a new ticker
  const handleAddTicker = () => {
    const upperTicker = newTicker.trim().toUpperCase();
    if (upperTicker && !tickers.includes(upperTicker)) {
      setTickers((prevTickers) => [...prevTickers, upperTicker]);
    }
    setNewTicker('');
  };

  // Remove a ticker
  const handleRemoveTicker = (tickerToRemove) => {
    setTickers((prevTickers) => prevTickers.filter((t) => t !== tickerToRemove));
    setDataMap((prevDataMap) => {
      const { [tickerToRemove]: _, ...rest } = prevDataMap;
      return rest;
    });
    setLoadingMap((prev) => {
      const { [tickerToRemove]: _, ...rest } = prev;
      return rest;
    });
    setErrors((prevErrors) => prevErrors.filter((err) => err !== tickerToRemove));
  };

  // ============ AUTO-REFETCH LOGIC ============

  useEffect(() => {
    /**
     * Whenever tickers, startDate, endDate, or selectedInterval changes,
     * we re-fetch for all tickers. 
     * But first, if it's an intraday interval, check 60-day limit.
     */
    const dayDiff = (new Date(endDate) - new Date(startDate)) / (1000 * 60 * 60 * 24);

    // If intraday AND date range > 60 days, clamp it
    if ((selectedInterval === '15m' || selectedInterval === '1h') && dayDiff > 60) {
      alert('Intraday data (15m or 1h) cannot exceed a 60-day range. Clamping date range...');
      // clamp startDate to be endDate - 60 days
      const newStart = new Date(endDate);
      newStart.setDate(newStart.getDate() - 60);

      const yyyy = newStart.getFullYear();
      const mm = String(newStart.getMonth() + 1).padStart(2, '0');
      const dd = String(newStart.getDate()).padStart(2, '0');
      const clampedStart = `${yyyy}-${mm}-${dd}`;

      setStartDate(clampedStart);
      // We'll return here so we don't fetch with the invalid range
      return;
    }

    // For each ticker, fetch if we haven't already
    tickers.forEach((ticker) => {
      fetchTickerData(ticker);
    });
    // eslint-disable-next-line
  }, [tickers, startDate, endDate, selectedInterval]);

  // 3) Decide chart height
  const chartBlockHeight = isMobile ? '400px' : '400px';

  // ============ RENDER ============

  return (
    <div className="dashboard-wrapper">
      {/* Top bar with Ticker Input */}
      <div className="top-bar">
        <div className="add-ticker">
          <input
            type="text"
            value={newTicker}
            onChange={(e) => setNewTicker(e.target.value)}
            placeholder="Add ticker (e.g., GOOG)"
          />
          <button onClick={handleAddTicker}>Add</button>
        </div>
        <div className="ticker-tags">
          {tickers.map((ticker) => (
            <div className="ticker-tag" key={ticker}>
              {ticker}
              <button className="remove-ticker" onClick={() => handleRemoveTicker(ticker)}>
                X
              </button>
            </div>
          ))}
        </div>
      </div>

      {/* Date range + Timeframe row */}
      <div className="date-range-row" style={{ padding: '0 10px', marginTop: '10px' }}>
        <label style={{ marginRight: '8px' }}>
          Start:
          <input
            type="date"
            value={startDate}
            onChange={(e) => setStartDate(e.target.value)}
            style={{ marginLeft: '4px' }}
          />
        </label>
        <label style={{ marginRight: '8px' }}>
          End:
          <input
            type="date"
            value={endDate}
            onChange={(e) => setEndDate(e.target.value)}
            style={{ marginLeft: '4px' }}
          />
        </label>
        <label style={{ marginRight: '8px' }}>
          Timeframe:
          <select
            value={selectedInterval}
            onChange={(e) => setSelectedInterval(e.target.value)}
            style={{ marginLeft: '4px' }}
          >
            {/* Only the intervals you allow */}
            {/* <option value="15m">15m</option> */}
            <option value="1h">1h</option>
            <option value="1d">1d (Daily)</option>
            {/* <option value="5d">5d</option> */}
          </select>
        </label>
      </div>

      {/* Charts */}
      <div className="charts-section">
        <div className="charts-container">
          {tickers.map((ticker) => {
            const data = dataMap[ticker];
            const isLoading = loadingMap[ticker];
            const hasError = errors.includes(ticker);

            return (
              <div className="ticker-card" key={ticker}>
                <h2>{ticker}</h2>
                {isLoading && <p>Loading data for {ticker}...</p>}
                {hasError && (
                  <p style={{ color: 'red' }}>Failed to load data for {ticker}.</p>
                )}
                {data && (
                  <div className="chart-block" style={{ height: chartBlockHeight }}>
                    {/* 80% flex item */}
                    <div className="main-chart-flex">
                      <Chart
                        dates={data.dates}
                        open={data.open}
                        high={data.high}
                        low={data.low}
                        close={data.close}
                        volume={data.volume}
                      />
                    </div>
                    {/* 20% flex item */}
                    <div className="rsi-chart-flex">
                      <RSIChart
                        dates={data.dates}
                        rsi={data.rsi}
                      />
                    </div>
                  </div>
                )}
              </div>
            );
          })}
        </div>
      </div>

      {/* Global error message for multiple tickers */}
      {errors.length > 0 && (
        <div className="error-message">
          <p>Failed to fetch data for: {errors.join(', ')}</p>
        </div>
      )}

      {/* Popup for Yahoo Finance / 400 errors */}
      {popupError && (
        <div className="popup-overlay">
          <div className="popup-content">
            <p style={{ color: 'red' }}>{popupError}</p>
            <button onClick={() => setPopupError(null)}>Close</button>
          </div>
        </div>
      )}
    </div>
  );
}

export default Dashboard;
