// RSIChart.js
import React from 'react';
import Plot from 'react-plotly.js';
import './RSIChart.css';

function RSIChart({ dates, rsi }) {
  return (
    <div className="rsi-chart-wrapper">
      <Plot
        data={[
          {
            x: dates,
            y: rsi,
            type: 'scatter',
            mode: 'lines',
            line: { color: 'blue' },
            name: 'RSI',
          },
        ]}
        layout={{
          autosize: true,
          margin: { t: 10, r: 10, b: 20, l: 40 },
          yaxis: { range: [0, 100], title: 'RSI' },
          shapes: [
            {
              type: 'line',
              xref: 'paper',
              x0: 0,
              x1: 1,
              y0: 70,
              y1: 70,
              line: { dash: 'dash', color: 'red' },
            },
            {
              type: 'line',
              xref: 'paper',
              x0: 0,
              x1: 1,
              y0: 30,
              y1: 30,
              line: { dash: 'dash', color: 'green' },
            },
          ],
        }}
        config={{
          responsive: true,
          displayModeBar: false,
        }}
        useResizeHandler
        style={{ width: '100%', height: '100%' }}
      />
    </div>
  );
}

export default RSIChart;
