// Home.js
import React from 'react';
import { useNavigate } from 'react-router-dom';
import './Home.css'; // 1) Import your new CSS file

function Home() {
  const navigate = useNavigate();

  const handleLogin = () => {
    navigate('/login'); // Redirect to login page
  };

  return (
    <div className="home-page">
      <h1>Welcome to the Stock Dashboard App</h1>
      <p>Navigate to the dashboard to view and manage stock data.</p>
      <button className="home-button" onClick={handleLogin}>
        Login
      </button>
    </div>
  );
}

export default Home;
