// Chart.js
import React from 'react';
import Plot from 'react-plotly.js';
import './Chart.css';

function Chart({ dates, open, high, low, close, volume }) {
  return (
    <div className="chart-wrapper">
      <Plot
        data={[
          {
            x: dates,
            open,
            high,
            low,
            close,
            type: 'candlestick',
            xaxis: 'x',
            yaxis: 'y',
            name: 'Price',
          },
          {
            x: dates,
            y: volume,
            type: 'bar',
            xaxis: 'x',
            yaxis: 'y2',
            opacity: 0.5,
            name: 'Volume',
          },
        ]}
        layout={{
          autosize: true,
          grid: { rows: 2, columns: 1, heights: [0.7, 0.3], pattern: 'independent' },
          xaxis: {
            domain: [0, 1],
            anchor: 'y',
            rangeslider: { visible: false },
          },
          yaxis: { domain: [0.4, 1] },
          yaxis2: { domain: [0, 0.3] },
          margin: { t: 10, b: 10, l: 40, r: 10 },
          showlegend: false,
        }}
        config={{
          responsive: true,
          displayModeBar: false,
        }}
        useResizeHandler
        style={{ width: '100%', height: '100%' }}
      />
    </div>
  );
}

export default Chart;
