//App.js
import React from 'react';
import { BrowserRouter as Router, Routes, Route, Link, Navigate } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';  // 1) Import useMediaQuery
import Home from './pages/Home';
import Dashboard from './pages/Dashboard';
import Login from './pages/Login';
import { breakpoints } from './utils/breakpoints'; // 2) Import our breakpoint constants
import './App.css';

function ProtectedRoute({ children }) {
  const token = localStorage.getItem('authToken');
  return token ? children : <Navigate to="/login" />;
}

function App() {
  // 3) Use the mobile breakpoint from breakpoints.js
  const isMobile = useMediaQuery({ maxWidth: breakpoints.mobile - 1 });

  return (
    <Router>
      {/*
        4) Conditionally add the "App-mobile" class if it's mobile.
        This is optional but shows how you might style differently for mobile.
      */}
      <div className={isMobile ? 'App App-mobile' : 'App'}>
        <nav className="nav-bar">
          <Link to="/" className="nav-button">Home</Link>
          <Link to="/dashboard" className="nav-button">Dashboard</Link>
        </nav>

        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/login" element={<Login />} />
          <Route
            path="/dashboard"
            element={
              <ProtectedRoute>
                <Dashboard />
              </ProtectedRoute>
            }
          />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
